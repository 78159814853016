import state from './moduleSubCategoryProductManagementState.js'
import mutations from './moduleSubCategoryProductManagementMutations.js'
import actions from './moduleSubCategoryProductManagementActions.js'
import getters from './moduleSubCategoryProductManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

