import axios from '@/axios.js'

export default {
  fetchSubCategoryProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/sub-category-product')
        .then(({data: response}) => {
          commit('SET_SUB_CATEGORY_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchSubCategoryProduct (context, subCategoryProductID) {
    return new Promise((resolve, reject) => {
      axios.get(`/sub-category-product/${subCategoryProductID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeSubCategoryProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/sub-category-product', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateSubCategoryProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/sub-category-product/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroySubCategoryProduct ({ commit }, subCategoryProductID) {
    return new Promise((resolve, reject) => {
      axios.delete(`sub-category-product/${subCategoryProductID}`)
        .then((response) => {
          commit('REMOVE_RECORD', subCategoryProductID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
